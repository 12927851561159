import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/blog/customer-service.jpg';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        The number of ideas and possibilities to boost e-commerce sales might be overwhelming.
        Sometimes it’s hard to choose the best solution to stand out because you never know if your
        strategy will work. There are many factors that should be taken into consideration, but
        customer service is one that everyone should never forget about.
      </p>
      <br />
      <h3>Look at the numbers</h3>
      <p>
        More than half of consumers claim they have higher expectations for customer service than
        they had last year – Microsoft data says (The Microsoft 2017 State of Global Customer
        Service). The survey shows that 96% of respondents see customer service as something that
        has an impact on their choices. 76% of respondents wish they could see their full history of
        shopping, data, and the level of overall satisfaction. What is the conclusion so far?
        Customer service is highly important these days. A brand should realise that fact as soon as
        possible.
      </p>
      <br />

      <h3>What’s most important?</h3>
      <p>
        It may seem that customer service is the main goal now, not only for the e-commerce market.
        However, there are some challenges that need to be faced while mastering your CS like a lack
        of physical interaction with clients and difficulty in building relations. What really
        matters is the offer, assortment, the team, the decor, and the atmosphere in the place. Take
        care of your customer experience, user interface, and content – they are your key to
        success. Everyone has the greatest offer, but only a few provide excellent customer service
        that stands out and stays in the customers’ minds for a long time. If you want to build
        trust, you need to remember that it won’t happen in a heartbeat! Customer service can make
        your satisfied clients come back to your shop. But if your client service doesn’t work, they
        will never come back. What’s more, they won’t leave you any feedback and spread the word
        about it to their family and friends. Sounds tough, doesn’t it? Recommendations and social
        proof are crucial when it comes to boosting sales in e-commerce. Again, it takes time to get
        satisfying feedback, but it’s worth it. Try with personalised messages to make it happen
        much faster. Discounts offered in return for a recommendation might work, too!
      </p>
      <br />

      <h3>Customer service is what you keep in mind for years </h3>
      <p>
        While a few years ago buying something online was caused only by seeing products offline
        with a higher price, the game has changed now. These days, online shopping is more like a
        solution to many problems people face every day. It’s simple – you get inspired scrolling
        social media, or you see something on a website you’re on, then you buy it a few seconds
        after that. It’s called a „shopping impulse”. Time is not the only aspect that has changed
        lately. So has free delivery or return. It was something new, and it had a „wow” factor a
        few years ago. Now it’s just another service we take for granted. If it’s not available,
        people will likely give up and close a tab with your shop. Right moves, quick responses, and
        flexible availability are what you should focus on if you want to take your customer service
        on the next level. One mistake and other shop’s customer service will satisfy your
        ex-potential client.
      </p>
      <br />

      <h3>Six steps to the best possible customer experience</h3>
      <p>How to make it better?</p>

      <h3>Check the list down below and improve your efforts: </h3>
      <ul>
        <li>
          Make sure you offer a variety of shopping and payment methods. Remember that your
          competitors do offer them and if you don’t… It may end up with losing customers.
        </li>
        <li>
          Shop security is a must. Do you provide an SSL certificate? You should. Otherwise, people
          won’t decide to trust you.
        </li>
        <li>
          How about the website loading time? Check if there are any plugins that can improve it.
        </li>
        <li>
          Can a customer find a help center or a web chat on your website? Try to do your best when
          it comes to customer service.
        </li>
        <li>Do not forget about implementing up-selling and cross-selling methods.</li>
        <li>
          Communicate delivery and return policies in a transparent way. Make sure your potential
          customers know about various methods of delivery.
        </li>
        <li>
          Once a support request comes through, send an immediate reply so the customer knows you’ve
          taken note of their problem. You can also use a{' '}
          <a
            href="https://www.mailmunch.com/blog/free-autoresponders/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            free autoresponder tool
          </a>{' '}
          to automate replies so every customer receives a timely update.
        </li>
      </ul>
      <br />
      <h3>Tips & tools</h3>
      <p>
        You may have the best customer service crew, but it’s more than likely that you’ll face some
        challenges when working with people – your clients. The most crucial thing for you is to
        know how to deal with them, and that is why we have some dos and don’ts for you:
      </p>
      <br />
      <h3>Keep calm…</h3>
      <p>
        …and make sure your clients are calm as well. To do that you need to have access to a
        complex database of information about them and their interactions with your business. Not
        only will you save your time and efforts, but also you’ll make your clients satisfied as
        they won’t have to repeat themselves again,] and again. It’s truly important, especially
        when emotions run really high. You may think you can do it on your own, but it’s even better
        to use some tools that can optimise your efforts.{' '}
        <Link rel="noopener noreferrer nofollow" target="_blank" href="https://cloudtalk.io">
          CloudTalk.io
        </Link>{' '}
        is one of the best tools for call centers. It provides personalisation what ensures an
        individual approach. What’s more, CloudTalk supports external e-commerce systems and social
        platforms, so that you can get a full overview of needed data.
      </p>
      <br />
      <h3>Follow your clients’ steps</h3>
      <p>
        Your goal is to make relations with your clients. How could you do that if you wouldn’t be
        where your customers are? It may costs you a lot of time and effort, but it’s truly
        important. If someone wants to make a complaint on Instagram, they want to talk to you on
        Instagram, not Facebook or LinkedIn. Once you want to redirect them between platforms, you
        may never see them at your shop again. If you’re curious how people act on your website,
        check out{' '}
        <Link
          rel="noopener"
          target="_blank"
          href="/signup?utm_source=blog&utm_campaign=the-importance-of-customer-service"
        >
          LiveSession
        </Link>{' '}
        tool as it analyses customers’ behavior and helps you to understand their digital
        experience. Have you ever wondered what makes your clients frustrated? Now it’s possible to
        get to know about it! Big picture insights will show you a summary of specific segments of
        users.
      </p>
      <br />
      <h3>Be helpful</h3>
      <p>
        As we’ve said it before – recommendations are something you could die for in e-commerce.
        Many salespeople think that it’s too expensive and see no potential in covering their sites
        in content that actually could help customers find the answers to their questions. To be
        honest, it’s not that scary, not at all!{' '}
      </p>
      <p>
        <Link rel="noopener nofollow" target="_blank" href="https://useresponse.com">
          UseResponse
        </Link>{' '}
        helps in building a help center, customer feedback platform, and provides a knowledge base
        for self-service support. Your potential customers will thank you for publishing that native
        content. However, this kind of tool should never replace customer support completely. It
        just minimises requests, tickets, and complaints.
      </p>
      <br />
      <h3>Do not forget about internal communication</h3>
      <p>
        If you want to maintain and develop a successful customer service team, you need to
        implement a well-organised internal communication system.That’s why the tools are for!{' '}
        <Link rel="noopener nofollow" target="_blank" href="https://stackfield.com">
          Stackfield
        </Link>{' '}
        will help you to manage files, exchange messages, and delegate tasks. Think about CRM as
        it’s not an option, it’s a must. Here comes{' '}
        <Link rel="noopener nofollow" target="_blank" href="https://livespace.io">
          Livespace
        </Link>{' '}
        – a tool that makes sales management more effective and predicts better forecasts. It helps
        you measure your results and view statistics of your operations. Their inbuilt sales process
        increases the probability of winning deals and getting sales. Totally worth trying!
      </p>

      <h3>Important facts:</h3>
      <ul>
        <li>
          every brand deserves impressive customer service, and it’s not a luxury, it’s a standard,
        </li>
        <li>
          it’s important to take care of clients before and after transactions – it increases the
          chances of client retention,
        </li>
        <li>
          building relations is not wasting your time, it’s a process that takes time and constant
          analysis.
        </li>
      </ul>
      <p>
        In the past, the only thing that mattered was the price. Now it’s all about the emotions. A
        reasonable price and „buy now” button are not the only things that people need now. How will
        you know your customer service is good enough? Look at your brand and see undeniable
        benefits such as higher sales, higher client retention, a high-level of loyalty, and trust,
        better recognition and many more…
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'The importance of customer service',
  url: '/blog/the-importance-of-customer-service',
  description: `Sometimes it’s hard to choose the best solution to
  stand out because you never know if your strategy will work.`,
  author,
  img: cover,
  date: '2019-06-03',
  category: 'growth',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial="The number of ideas and possibilities to boost e-commerce sales might be overwhelming. Sometimes it’s hard to choose the best solution to stand out because you never know if your strategy will work. There are many factors that should be taken into consideration, but  customer service is one that everyone should never forget about."
    img={frontmatter.img}
  />
);
